<template>
  <tr>
    <td class="pl-0">
      <div class="d-flex align-items-center">
        <div class="symbol symbol-50 symbol-light mr-4">           
          <Avatar :avatarData=user.avatar></Avatar>  
        </div>
        <div>
          <router-link class="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg" :to="{ name: 'users_overview', params: { id: user.user_id }}">{{ user.full_name }}</router-link>
          <span class="text-muted font-weight-bold d-block">{{ user.position.name }}</span>
        </div>
      </div>
    </td>
    <td>
      <span class="text-dark-75 font-weight-bolder d-block font-size-lg">{{ user.email }}</span>
      <span class="text-muted font-weight-bold" v-if="user.confirmed == 1">
        <small class="fa fa-check text-success"></small> {{ $t("users.title.account_is_confirmed") }}
      </span>
      <span class="text-muted font-weight-bold" v-else>
        <small class="fa fa-times text-danger"></small> {{ $t("users.title.account_is_unconfirmed") }}
      </span>
    </td>
    <td>
      <span class="text-dark-75 d-block font-size-lg">{{ user.login }}</span>
    </td>
    <td>
      <b-badge variant="success" v-if="user.active == 1">{{ $t("users.title.active") }}</b-badge>
      <b-badge variant="danger" v-else>{{ $t("users.title.unactive") }}</b-badge>
    </td>
    <td class="pr-0 text-right">
      <router-link
        :to="{ name: 'users_overview', params: { id: user.user_id }}"
        v-b-tooltip.top='$t("button.detail")'
        class="btn btn-icon btn-light btn-hover-primary btn-sm mr-2"
      >
        <span class="svg-icon svg-icon-md svg-icon-primary">
          <inline-svg src="/media/svg/icons/General/Search.svg" />
        </span>
      </router-link>
      <router-link
        :to="{ name: 'users_roles', params: { id: user.user_id }}"
        v-b-tooltip.top='$t("button.permissions")'
        class="btn btn-icon btn-light btn-hover-primary btn-sm mr-2"
      >
        <span class="svg-icon svg-icon-md svg-icon-primary">
          <inline-svg src="/media/svg/icons/General/Lock.svg" />
        </span>
      </router-link>
      <b-dropdown id="dropdown-bottom" variant="btn-light" size="sm">
        <template #button-content>
          <span class="svg-icon svg-icon-md svg-icon-primary">
            <inline-svg src="/media/svg/icons/General/Settings-1.svg" />
          </span>
        </template>
        <b-dropdown-item v-if="user.confirmed != 1" @click.prevent="verifyAccount"><span class="navi-icon"><i class="flaticon-refresh mr-3 text-success"></i></span>{{ $t("users.button.verify_account") }}</b-dropdown-item>
        <b-dropdown-item v-if="user.confirmed != 1" @click.prevent="sendVerificationEmail"><span class="navi-icon"><i class="flaticon-multimedia mr-3 text-success"></i></span>{{ $t("users.button.send_verify_email") }}</b-dropdown-item>
        <b-dropdown-divider v-if="user.confirmed != 1"></b-dropdown-divider>
        <b-dropdown-item v-if="user.active != 1" @click.prevent="activateAccount"><span class="navi-icon"><i class="flaticon2-checkmark mr-3 text-success"></i></span>{{ $t("users.button.activate_account") }}</b-dropdown-item>
        <b-dropdown-item v-if="user.active == 1" @click.prevent="deactivateAccount"><span class="navi-icon"><i class="flaticon-circle mr-3 text-danger"></i></span>{{ $t("users.button.deactivate_account") }}</b-dropdown-item>
      </b-dropdown>
    </td>
  </tr>
</template>
<script>
import ApiService from "@/core/services/api.service";
import Avatar from "@/view/content/Avatar";
export default {
  props: {
    user: Object
  },
  components: {
    Avatar
  },
  methods: {
    verifyAccount() {
      ApiService.apiPost("/users/verify", { 'user_id': this.user.user_id})
        .then(() => {
          this.$parent.getUsers();
        });
    },
    sendVerificationEmail() {
        ApiService.apiPost("/users/send-verification-email", { 'user_id': this.user.user_id});
    },
    activateAccount() {
      ApiService.apiPost("/users/activate", { 'user_id': this.user.user_id})
        .then(() => {
          this.$parent.getUsers();
        });
    },
    deactivateAccount() {
      ApiService.apiPost("/users/deactivate", { 'user_id': this.user.user_id})
        .then(() => {
          this.$parent.getUsers();
        });
    },
  }
};
</script>