<template>    
    <span class="symbol-label" >
        <inline-svg v-if="!avatarData" src="/media/svg/avatars/001-boy.svg" class="h-75 align-self-end" />
        <img v-if="avatarData" :src="'data:image/jpeg;base64,'+avatarData.avatar_base_64"  style="max-width: 100%; max-height: 100%;">
    </span>  
</template>
<script>
export default {
  name: "avatar",
  props: {
    avatarData: null,
  },
};
</script>