<template>
  <div>
    <hr />
    <div class="d-flex justify-content-between align-items-center flex-wrap">
      <div class="d-flex align-items-center">
        <select
          class="form-control form-control-sm text-primary font-weight-bold mr-4 border-0 bg-light-primary"
          style="width: 75px" v-model="itemsPerPage"
        >
          <option value="10">10</option>
          <option value="15">15</option>
          <option value="20">20</option>
          <option value="30">30</option>
          <option value="50">50</option>
          <option value="100">100</option>
        </select>
        <span class="text-muted">
          {{ $t("pagination.showing") }} <strong>{{ data.from }} - {{ data.to }}</strong> {{ $t("pagination.records") }} {{ $t("pagination.from") }} <strong>{{ data.total }}</strong>
        </span>
      </div>
      <div class="d-flex flex-wrap mr-3">
        <button class="btn btn-icon btn-sm btn-light-primary mr-2 my-1" v-if="data.current_page > 1" v-on:click="setPage(1)"><i class="ki ki-bold-double-arrow-back icon-xs"></i></button>

        <button class="btn btn-icon btn-sm btn-light-primary mr-2 my-1" v-if="data.current_page > 1" v-on:click="setPage(data.current_page - 1)"><i class="ki ki-bold-arrow-back icon-xs"></i></button>

        <!--
        <button class="btn btn-icon btn-sm border-0 btn-hover-primary mr-2 my-1" v-for="index in data.last_page" :key="index" v-on:click="setPage(index)" :class="(index==data.current_page)?'active':''">{{ index }}</button>
        -->

        <button class="btn btn-icon btn-sm border-0 btn-hover-primary mr-2 my-1" v-for="index in this.links" :key="index" v-on:click="setPage(index)" :class="(index==data.current_page)?'active':''">{{ index }}</button>


        <button class="btn btn-icon btn-sm btn-light-primary mr-2 my-1" v-if="data.current_page != data.last_page" v-on:click="setPage(data.current_page + 1)"><i class="ki ki-bold-arrow-next icon-xs"></i></button>

        <button class="btn btn-icon btn-sm btn-light-primary mr-2 my-1" v-if="data.current_page != data.last_page" v-on:click="setPage(data.last_page)"><i class="ki ki-bold-double-arrow-next icon-xs"></i></button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    data: Object,
  },
  watch: {
    'itemsPerPage' : function () {
        this.setPerPage(this.perpage);
    }
  },
  methods: {
    setPage(newPage) {
      this.$parent.onUpdatePage(newPage);
    },
    setPerPage() {
      this.$parent.onUpdatePage(1);
    }
  },

  computed: {
    itemsPerPage: {
      get () {
        return this.$store.state.pagination.pagination.itemsPerPage;
      },
      set (value) {
        this.$store.commit('setItemsPerPage', value);
      }
    },
    links: function () {
      let current = this.data.current_page;
      let last = this.data.last_page;
      let delta = 2;
      let left = current - delta;
      let right = current + delta + 1;
      let range = [];
      for (let i = 1; i <= last; i++) {
        if ( i >= left && i < right) {
            range.push(i);
        }
      }
      return range;
    }
  }
};
</script>