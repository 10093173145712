<template>
  <div>
    <div class="row gutter-b">
      <div class="col-md-12">
        <div class="card card-custom">
          <div class="card-header card-header-fixed border-0 px-6">
            <div class="row align-items-center col-10 px-0">
                  <div class="col-3 pr-0">
                    <div class="input-icon">
                      <input 
                        type="text" 
                        class="form-control form-control-solid" 
                        :placeholder="$t('users.placeholder.search')"
                        v-model="search"
                        >
                      <span>
                        <i class="flaticon2-search-1 text-muted"></i>
                      </span>
                    </div>
                  </div>
                  <div class="col-3">
                    <vs-select v-model="groups" :options="roles" v-if="roles" label="name" :reduce="roles => roles.role_id" :placeholder="$t('user.placeholder.search_roles')"/>
                  </div>
                  <b-button
                    variant="secondary"
                    class="mr-3 pr-2"
                     @click.prevent="removeFilters"
                  >
                    <i class="fa fa-times icon-md"></i>
                  </b-button>
                  <b-button
                    variant="primary"
                    @click.prevent="searchUsers"
                  >
                  {{ $t("button.filter") }}   
                  </b-button>

              </div>
              <div class="card-toolbar">
                <router-link :to="{name: 'users_create'}" class="btn btn-success">
                  <span class="svg-icon svg-icon-md svg-icon-white">
                    <inline-svg src="/media/svg/icons/Communication/Add-user.svg" />
                  </span>
                  {{ $t("users.button.create") }}
                </router-link>
              </div>
          </div>
        </div>
      </div>
    </div>
    <!--begin::Card-->
    <div class="card card-custom" >
      <!--begin::Body-->
      <div class="card-body">
        <div class="table-responsive ">
          <table
            class="table table-head-custom table-head-bg table-vertical-center"
          >
            <thead>
              <tr class="text-left text-uppercase">
                <th style="min-width: 250px">{{ $t("label.name") }}</th>
                <th style="min-width: 100px">{{ $t("label.email") }}</th>
                <th style="min-width: 100px">{{ $t("label.login") }}</th>
                <th style="min-width: 100px">{{ $t("label.status") }}</th>
                <th style="min-width: 80px" class=" text-right">{{ $t("label.action") }}</th>
              </tr>
            </thead>
            <tbody v-if="!loading">
              <UserItem
                v-for="(user, index) in users.data"
                :key="index"
                :user="user"
              ></UserItem>
            </tbody>
          </table>
          <Pagination :data="users.meta" v-if="!loading"></Pagination>
        </div>
      </div>
      <!--end::Body-->
    </div>
    <!--end::Card-->
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import UserItem from "@/view/pages/core/users/list/UserItem";
import Pagination from "@/view/content/Pagination";
import ApiService from "@/core/services/api.service";

export default {
  name: "users",
  data() {
    return {
      loading: false,
      users: null,
      page: 1,
      roles: null,
      search: "",
      groups: null
    };
  },
  components: {
    UserItem,
    Pagination
  },
  beforeMount() {
    this.checkLastSearch();
    this.getUsers();
    this.getRoles();
  },
  metaInfo () { 
      return { title: this.$t("users.meta.subtitle.list")} 
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: this.$t("users.meta.title") , subtitle: this.$t("users.meta.subtitle.list") }]);
  },
  methods: {
    getUsers() {
      this.loading = true;
      ApiService.setHeader();
      ApiService.apiGet(
        "/users?page=" + this.page + "&perpage=" + this.perpage + "&search=" + this.search + "&groups=" + this.groups)
        .then((response) => {
          this.users = response.data;
          this.loading = false;
        });
    },
    getRoles() {
      this.loading = true;
      ApiService.setHeader();
      ApiService.apiGet("/roles")
        .then((response) => {
          this.roles = response.data.data;
          this.loading = false;
        });
    },
    onUpdatePage(newPage) {
      this.page = newPage;
      this.getUsers();
    },
    searchUsers(){
      this.page = 1;
      this.getUsers(); 
      this.userSearch = {
        search: this.search,
        groups: this.groups
      } 
    },
    removeFilters(){
      this.page = 1;
      this.groups = null;
      this.search = "";
      this.userSearch = {
        search: this.search,
        groups: this.groups
      };
      this.getUsers(); 
    },
    checkLastSearch(){
      let lastSearch = this.$store.state.search.userSearch;
      this.search = lastSearch.search;
      this.groups = lastSearch.groups;
    }
  },
  computed: {
    perpage() {
      return this.$store.state.pagination.pagination.itemsPerPage;
    },
    userSearch: {
      get () {
        return this.$store.state.search.userSearch;
      },
      set (value) {
        this.$store.commit('setUserSearch', value);
      }
    },
  }
};
</script>